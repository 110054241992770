.layout-topbar {
  .p-menubar {
    flex: 1 1 auto;
    position: relative;
    min-width: $minWidth;
    max-width: $maxWidth;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
  }

  @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);

  .p-menubar:not(.p-menubar-mobile-active)
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text,
  .p-menubar:not(.p-menubar-mobile-active)
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar:not(.p-menubar-mobile-active)
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:hover
    .p-menuitem-text,
  .p-menubar:not(.p-menubar-mobile-active)
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:hover
    .p-menuitem-icon,
  .p-button.p-link,
  .p-button.p-link:hover,
  .p-menubar .p-menubar-button {
    color: #fff;
  }

  .p-divider.p-divider-vertical:before {
    border-color: #fff;
  }

  .p-menubar {
    border: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover,
  .p-button.p-link:hover,
  .p-menubar .p-menubar-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .p-chip {
    color: #fff;
    background-color: transparent;
    padding: 0;
  }

  button {
    cursor: pointer;
  }
}
