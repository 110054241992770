/* General */
$fontSize: 14px;
$fontFamily: "Proxima Nova", helvetica, arial, sans-serif;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;
$focusShadowColor: #8dcdff;

/* Menu Common */
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: #eff6fb;

/* Menu Light */
$menuBgColorFirst: #f3f4f9;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #232428;
$menuitemHoverColor: #0388e5;
$menuitemActiveColor: #0388e5;
$menuitemActiveBgColor: #ffffff;
$menuitemBorderColor: rgba(207, 211, 224, 0.6);

/* Menu Dark */
//$menuDarkBgColorFirst:#4d505b;
//$menuDarkBgColorLast:#3b3e47;
$menuDarkBgColorFirst: #012a4c;
$menuDarkBgColorLast: #03345d;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: #0388e5;
$menuitemDarkActiveColor: #0388e5;
$menuitemDarkActiveBgColor: #2e3035;
$menuitemDarkBorderColor: #012a4c;

/* Topbar */
//$topbarLeftBgColor:#0388E5;
//$topbarRightBgColor:#07BDF4;
$topbarLeftBgColor: #047;
$topbarRightBgColor: #047;

$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: #c3e8fb;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #ffffff;

/* Widths */
$minWidth: 300px;
$maxWidth: 1410px;
